import { BASE_URL, GenericAttributes, getHeaders } from "./common";

export type SiteResponse = {
  siteId: string;
  partnerId: string;
  siteName: string;
  address: string;
  latitude: number;
  longitude: number;

  levelTypes: {
    [key: string]: SiteLevelType;
  };
  levelGroups: SiteLevelGroup[];
  attributes: GenericAttributes;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
};

export type SiteLevelGroup = {
  levelGroupId: string;
  levelGroupName: string;
  levels: string[];
  createdAt: string;
  createdBy: string;
};

export type SiteLevelType = {
  levelTypeName: string;
  levelTypeDescription: string;
  predecessorLevelTypes: string[];
  successorLevelTypes: string[];
};

export type SiteLevel = {
  levelId: string;
  siteId: string;
  partnerId: string;
  path: string;
  levelType: string;
  levelName: string;
  predecessorId?: string; // not set for ROOT
  successorIds: string[];
  levelDescription: string;
  address: string;
  longitude?: number; // not set for ROOT
  latitude?: number; // not set for ROOT
  altitude?: number; // not set for ROOT
  attributes: GenericAttributes;
  createdAt: string;
  createdBy: string;
};

export async function getSites(): Promise<SiteResponse[]> {
  const url = `${BASE_URL}/sites`;
  return await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`HTTP error, status: ${res.status}`);
    }
    return res.json();
  });
}

export async function getSiteDetail(siteId: string): Promise<SiteResponse> {
  const url = `${BASE_URL}/site/${siteId}`;
  return await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`HTTP error, status: ${res.status}`);
    }
    return res.json();
  });
}

export async function getSiteLevels(siteId: string): Promise<SiteLevel[]> {
  const url = `${BASE_URL}/site/${siteId}/levels`;
  return await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`HTTP error, status: ${res.status}`);
    }
    return res.json();
  });
}

export async function getSiteLevelDetails(
  siteId: string,
  levelId: string,
): Promise<SiteLevel> {
  const url = `${BASE_URL}/site/${siteId}/level/${levelId}`;
  return await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`HTTP error, status: ${res.status}`);
    }
    return res.json();
  });
}
