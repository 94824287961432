export {};

declare global {
  interface Array<T> {
    asyncMap<U>(
      callbackfn: (value: T, index: number, array: T[]) => Promise<U>,
      thisArg?: any,
    ): Promise<U[]>;
  }
}

global.Array.prototype.asyncMap = async function (asyncFunction) {
  return await Promise.all(this.map(asyncFunction));
};
