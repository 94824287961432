import MetricCard from "./MetricCard";

const EnergyCard = ({ energyCardStats }) => {
  return (
    <div className="p-4">
      <p className="text-space50 text-heading3 mb-4">Data Summary</p>
      <div className="grid grid-cols-2 gap-4">
        {energyCardStats.map((cardItem, index) => (
          <div key={index}>
            <MetricCard metricCardStat={cardItem} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default EnergyCard;
