const getBaseURL = () => {
  switch (process.env.REACT_APP_ENV) {
    case "production":
      return "https://ingestion.spectra.api.aerovy.com/v1";
    case "staging":
      return "https://ingestion.spectra.api.staging.aerovy.com/v1";
    case "development":
      return "https://ingestion.spectra.api.dev.aerovy.com/v1";
    default:
      return "/ingestion/v1";
  }
};

export const BASE_URL = getBaseURL();

export const getHeaders = () => {
  return {
    Accept: "*/*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("auth0_token")}`,
  };
};

export type GenericAttributes = {
  [key: string]: string;
};
