import { BASE_URL, GenericAttributes, getHeaders } from "./common";

export type Thing = {
  thingId: string;
  thingName: string;
  thingType: string;
  thingDescription: string;
  model: string;
  isSimulated: boolean;
  longitude: number;
  latitude: number;
  altitude: number;
  integrationId: string;
  partnerId: string;
  siteId: string;
  levelId: string;
  attributes: GenericAttributes;
  createdAt: string;
  createdBy: string;
};

export async function getThings(): Promise<Thing[]> {
  const url = `${BASE_URL}/things`;
  return await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`HTTP error, status: ${res.status}`);
    }
    return res.json();
  });
}

export async function getThingTypes(): Promise<string[]> {
  const url = `${BASE_URL}/things/types`;
  return await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`HTTP error, status: ${res.status}`);
    }
    return res.json();
  });
}

export async function getThingsFromSite(siteId: string): Promise<Thing[]> {
  const url = `${BASE_URL}/site/${siteId}/things`;
  return await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`HTTP error, status: ${res.status}`);
    }
    return res.json();
  });
}

export async function getThingFromSite(
  siteId: string,
  thingId: string,
): Promise<Thing> {
  const url = `${BASE_URL}/site/${siteId}/thing/${thingId}`;
  return await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`HTTP error, status: ${res.status}`);
    }
    return res.json();
  });
}

export async function getThingsFromLevelGroup(
  siteId: string,
  levelGroupId: string,
): Promise<Thing[]> {
  const url = `${BASE_URL}/site/${siteId}/levelGroup/${levelGroupId}/things?onlyIds=false`;
  return await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`HTTP error, status: ${res.status}`);
    }
    return res.json();
  });
}

export async function getThingsForLevel(
  siteId: string,
  levelId: string,
): Promise<Thing[]> {
  const url = `${BASE_URL}/site/${siteId}/level/${levelId}/things?onlyIds=false`;
  return await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`HTTP error, status: ${res.status}`);
    }
    return res.json();
  });
}
