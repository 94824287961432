import { Auth0Provider } from "@auth0/auth0-react";

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import "./index.css";
import "./utils/arrayUtils.ts";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Auth0Provider
      domain="auth.spectra.dev.aerovy.com"
      clientId="A0MSTVYk3mWrHVyS4RuL25msSp9QKCOS"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: "organize",
        scope:
          "openid profile read:data_places read:data_things read:ingest_places read:ingest_things write:ingest_places write:ingest_things read:ingest_integrations write:ingest_integrations read:ingest write:ingest read:ingest_admin write:ingest_admin",
      }}
    >
      <App />
    </Auth0Provider>
  </BrowserRouter>,
);
