import { Dayjs } from "utils/dayjs";

export const formatDate = (day: Dayjs) => {
  return day.format("YYYY-MM-DD HH:mm:ss.SSS");
};

export const NO_DATA_ROW = [
  { scalarValue: 0 },
  { scalarValue: 0 },
  { scalarValue: 0 },
  { scalarValue: 0 },
];
