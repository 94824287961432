import { DeviceTile } from "components";


const Section = ({ title, devices }) => {
  return (
    <>
      <p className="text-heading3 text-space50">{title}</p>
      <div className="flex flex-wrap gap-4">
        {devices.map((device) => (
          <DeviceTile device={device} key={device.thingName} />
        ))}
      </div>
    </>
  );
};

const SkyportsChargerStatus = ({ chargers, batteries, meters }) => {
  return (
    <div>
      <div className="flex flex-col gap-2 p-3">
        {chargers.length !== 0 && (
          <Section title="Chargers" devices={chargers} />
        )}
        {batteries.length !== 0 && (
          <Section title="Batteries" devices={batteries} />
        )}
        {meters.length !== 0 && (
          <Section title="Meters" devices={meters} />
        )}
      </div>
    </div>
  );
};

export default SkyportsChargerStatus;