import { useState } from "react";

import { ReactComponent as Filter } from "images/icons/filter.svg";

const SingleCategoryFilter = ({
  options,
  defaultSelected,
  onChange,
}: {
  options: string[];
  defaultSelected: string;
  onChange: (value: string) => void;
}) => {
  const [current, setCurrent] = useState(defaultSelected);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const changeValue = (value: string) => {
    setIsMenuOpen(false);
    setCurrent(value);
    onChange(value);
  };

  return (
    <div className="relative">
      <button
        className="px-3.5 py-2 rounded-full border border-space80 justify-end items-center gap-1 cursor-pointer hover:bg-gray95 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue90 focus:ring-blue90 flex"
        onClick={toggleMenu}
      >
        <div className="text-space70 text-xs font-medium leading-[14px]">
          {current}
        </div>
        <div className="text-space70 text-xs">
          <Filter />
        </div>
      </button>
      <div
        className={`origin-top-right absolute right-0 py-1 mt-2 border border-gray95 rounded-md shadow-lg bg-white divide-y focus:outline-none z-20 ${isMenuOpen ? "" : "hidden"}`}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        {options.map((option) => (
          <button
            key={option}
            onClick={() => changeValue(option)}
            className={`px-4 w-full py-3 text-left text-xs ${current === option ? "text-space60 bg-blue90" : "hover:bg-gray95 text-space50"}`}
            role="menuitem"
            tabIndex={-1}
            id="options-menu"
            type="button"
          >
            {option}
          </button>
        ))}
      </div>
    </div>
  );
};

export { SingleCategoryFilter };
