const NoAccessPage = () => {
  return (
    <div>
      <div className="text-heading1 text-space50 pt-4 pb-5">Access Denied</div>
      <div className="flex">
        <p className="text-body">
          You do not have access to this page. Please contact your account
          administrator.
        </p>
      </div>
    </div>
  );
};

export default NoAccessPage;
