export const pageTree = ['Skyports', 'All Locations']
export const header = 'Operation Overview'
export const subheadings = []
export const displayProps = {
  "Chargers Faulted": <p className="font-bold">2</p>,
  "Fees Collected": <p className="font-bold">103</p>,
  "Number of Sites": <p className="font-bold">9</p>,
  "Average Charge": <><p className="font-bold mr-1">235</p>kWh</>,
  "Current Total Load": <><p className="font-bold mr-1">37</p>kWh</>,
  "Charging Events": <><p className="font-bold mr-1">3415</p><p className="mr-1 text-green-500">+105</p>vs. previous</>,
}

export const alertEvents = [
  [0, 'Faulted', '08/12/23 05:23', 'Dubai', 'Dubai International Airport', 'Charger', 'Short subhead'],
  [1, 'Faulted', '08/12/23 05:23', 'Dubai', 'Dubai Downtown', 'Charger', 'Short subhead'],
  [2, 'Limited', '08/12/23 05:23', 'Dubai', 'Palm Jumeirah', 'Charger', 'Short subhead'],
  [3, 'Limited', '08/12/23 05:23', 'Paris', 'Paris-Le Bourget Airport', 'Charger', 'Short subhead'],
  [3, 'Limited', '08/12/23 05:23', 'Paris', 'Paris-Le Bourget Airport', 'Charger', 'Short subhead'],
  [4, 'Limited', '08/12/23 05:23', 'Paris', 'Austerlitz Barge Vertiport', 'Charger', 'Short subhead'],
]

export const locationTree = {
  'Dubai': [
    {
      'abrev': 'LBG',
      'location': 'Dubai International Airport Long Beach, CA',
      'chargers': [
        {
          'id': '1',
          'state': 'Faulted',
          'identifier': 'Charger Identifer'
        },
        {
          'id': '2',
          'state': 'Clear',
          'identifier': 'Charger Identifer'
        },
        {
          'id': '3',
          'state': 'Clear',
          'identifier': 'Charger Identifer'
        },
        {
          'id': '4',
          'state': 'Clear',
          'identifier': 'Charger Identifer'
        }
      ]
    },
    {
      'abrev': 'PAO',
      'location': 'Palm Jumeriah Palo Alto, CA',
      'chargers': [
        {
          'id': '1',
          'state': 'Limited',
          'identifier': 'Charger Identifer'
        },
        {
          'id': '2',
          'state': 'Clear',
          'identifier': 'Charger Identifer'
        },
        {
          'id': '3',
          'state': 'Clear',
          'identifier': 'Charger Identifer'
        },
        {
          'id': '4',
          'state': 'Clear',
          'identifier': 'Charger Identifer'
        }
      ]
    }
  ],
  'Paris': [
    {
      'abrev': 'CDG',
      'location': 'Charles de Gaulle Airport Paris, France',
      'chargers': [
        {
          'id': '1',
          'state': 'Faulted',
          'identifier': 'Charger Identifer'
        },
        {
          'id': '2',
          'state': 'Faulted',
          'identifier': 'Charger Identifer'
        },
        {
          'id': '3',
          'state': 'Faulted',
          'identifier': 'Charger Identifer'
        },
        {
          'id': '4',
          'state': 'Limited',
          'identifier': 'Charger Identifer'
        },
        {
          'id': '5',
          'state': 'Clear',
          'identifier': 'Charger Identifer'
        }
      ]
    },
  ],
}