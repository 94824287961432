import { SiteResponse, getSites } from "api/ingestion/places.ts";
import { Thing, getThingTypes, getThings } from "api/ingestion/things.ts";
import { DeviceDetail } from "components";
import { SingleCategoryFilter } from "components/controls/SingleCategoryFilter.tsx";
import {
  SelectedDeviceProvider,
  useSelectedDevice,
} from "context/SelectedDeviceContext.tsx";
import {
  SelectedTimeRangeProvider,
  TimeRangeSelector,
} from "context/SelectedTimeRangeContext.tsx";

import { useEffect, useState } from "react";

import { DevicesBySite } from "./DevicesBySite.tsx";
import { DevicesByType } from "./DevicesByType.tsx";

const DeviceDetailWrapper = () => {
  // allows the use of the selected device context
  const { selectedDevice, setSelectedDevice } = useSelectedDevice();
  return (
    <DeviceDetail
      selectedDevice={selectedDevice}
      setSelectedDevice={setSelectedDevice}
    />
  );
};

const PageContent = () => {
  const [showBySite, setShowBySite] = useState(true);

  const [sites, setSites] = useState<SiteResponse[]>([]);
  const [deviceTypes, setDeviceTypes] = useState<string[]>([]);
  const [devices, setDevices] = useState<Thing[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const sites = await getSites();
      const allDeviceTypes = await getThingTypes();
      const allDevices = await getThings();
      setSites(sites);
      setDeviceTypes(allDeviceTypes);
      setDevices(allDevices);
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="flex flex-row w-full min-h-screen">
        <SelectedDeviceProvider>
          <div className="flex-1 h-screen overflow-y-auto">
            <div className="flex pt-4 pb-5 justify-between">
              <p className="text-heading1 text-space50">Devices</p>
              <div className="flex gap-2">
                <TimeRangeSelector />
                <SingleCategoryFilter
                  options={["Group by Site", "Group by Type"]}
                  defaultSelected={
                    showBySite ? "Group by Site" : "Group by Type"
                  }
                  onChange={(value) => setShowBySite(value === "Group by Site")}
                />
                {/* <button
                  type="submit"
                  className="border-blue40 bg-blue50 hover:bg-blue60 elevation-2 items-center justify-center gap-2 rounded border px-3 py-2 font-bold text-white hover:translate-y-[-2px] transition-transform"
                >
                  Add Device
                </button> */}
              </div>
            </div>

            {showBySite ? (
              <DevicesBySite
                sites={sites}
                devices={devices}
                deviceTypes={deviceTypes}
              />
            ) : (
              <DevicesByType
                sites={sites}
                devices={devices}
                deviceTypes={deviceTypes}
              />
            )}
          </div>
          <DeviceDetailWrapper />
        </SelectedDeviceProvider>
      </div>
    </>
  );
};

const DevicesPage = () => {
  return (
    <SelectedTimeRangeProvider>
      <PageContent />
    </SelectedTimeRangeProvider>
  );
};

export { DevicesPage };
