import { Timeseries } from "api/data.ts";
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { useState } from "react";

export const HorizontalTimeseriesChart = ({
  timeseries,
}: {
  timeseries: Timeseries | null;
}) => {
  const [focusedBar, setFocusedBar] = useState<number | null>(null);

  if (timeseries === undefined) {
    return (
      <div className="p-3">
        <p className="text-space50 text-heading3 mb-4">Energy Utilization</p>
        <p className="text-space70 text-caption">Loading...</p>
      </div>
    );
  }

  if (!timeseries) {
    return (
      <div className="p-3">
        <p className="text-space50 text-heading3 mb-4">Energy Utilization</p>
        <p className="text-space70 text-caption">
          No data for the selected timeframe
        </p>
      </div>
    );
  }

  const dateFormatter = (date: Date) => {
    return date.toLocaleDateString();
  };

  return (
    <div className="p-3">
      <p className="text-space50 text-heading3 mb-4">Energy Utilization</p>
      <ResponsiveContainer width="100%" height={275}>
        <BarChart
          data={timeseries.values}
          margin={{ right: 20, left: -20 }}
          barGap={0}
          barCategoryGap={0}
          onMouseMove={(state) => {
            if (state.isTooltipActive) {
              setFocusedBar(state.activeTooltipIndex);
            } else {
              setFocusedBar(null);
            }
          }}
        >
          <XAxis
            dataKey="time"
            stroke="#7F8284"
            scale={"time"}
            tickFormatter={dateFormatter}
          />
          <YAxis scale={"linear"} stroke="#7F8284" />
          <Tooltip cursor={false} formatter={(value) => value.toFixed(3)} />
          <Legend />
          <Bar type="monotone" dataKey="net" fill="#89A7BF" name="Net (kWh)">
            {timeseries.values.map((_, index) => (
              <Cell
                key={index}
                fill={focusedBar === index ? "#89A7BF" : "#6B89A2"}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export const SmallHorizontalTimeseriesChart = ({
  timeseries,
}: {
  timeseries: Timeseries | null;
}) => {
  const [focusedBar, setFocusedBar] = useState<number | null>(null);

  if (timeseries === undefined) {
    return (
      <div>
        <p className="text-space70 text-caption">Loading...</p>
      </div>
    );
  }

  if (!timeseries) {
    return (
      <div>
        <p className="text-space70 text-caption">
          No data for the selected timeframe
        </p>
      </div>
    );
  }

  const dateFormatter = (date: Date) => {
    return date.toLocaleDateString();
  };

  return (
    <div className="w-full">
      <ResponsiveContainer width="100%" height={275}>
        <BarChart
          data={timeseries.values}
          margin={{ right: 20, left: -20 }}
          barGap={0}
          barCategoryGap={0}
          onMouseMove={(state) => {
            if (state.isTooltipActive) {
              setFocusedBar(state.activeTooltipIndex);
            } else {
              setFocusedBar(null);
            }
          }}
        >
          <XAxis
            dataKey="time"
            stroke="#7F8284"
            scale={"time"}
            tickFormatter={dateFormatter}
          />
          <Tooltip cursor={false} formatter={(value) => value.toFixed(3)} />
          <Bar
            type="monotone"
            stackId="a"
            dataKey="net"
            fill="#6B89A2"
            name="Net (kWh)"
          >
            {timeseries.values.map((_, index) => (
              <Cell
                key={index}
                fill={focusedBar === index ? "#89A7BF" : "#6B89A2"}
              />
            ))}
          </Bar>
          <Bar
            type="monotone"
            stackId="a"
            dataKey="fwd"
            fill="#4E6D86"
            name="Fwd (kWh)"
          >
            {timeseries.values.map((_, index) => (
              <Cell
                key={index}
                fill={focusedBar === index ? "#6B89A2" : "#4E6D86"}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export const MicroHorizontalTimeseriesChart = ({
  timeseries,
}: {
  timeseries: Timeseries | null;
}) => {
  if (timeseries === undefined) {
    return (
      <div className="h-[52px] flex flex-col justify-center items-center">
        <p className="text-space80 text-caption">Loading...</p>
      </div>
    );
  }

  if (!timeseries) {
    return (
      <div className="h-[52px] flex flex-col justify-center items-center">
        <p className="text-space80 text-caption">
          No power utilization data for the past 7 days
        </p>
      </div>
    );
  }

  const netMax = timeseries.values.reduce(
    (acc, v) => Math.max(acc, v.net),
    -Infinity,
  );

  const fwdMax = timeseries.values.reduce(
    (acc, v) => Math.max(acc, v.fwd),
    -Infinity,
  );

  return (
    <div className="self-stretch h-[52px] pb-1 flex-col justify-center items-center gap-0.5 flex">
      <div className="self-stretch grow gap-0.5 justify-start items-end inline-flex">
        {timeseries.values.map((value, i) => (
          <div
            className="grow basis-0 h-full flex-col justify-end items-start inline-flex relative"
            key={i}
          >
            <div
              className="bg-slate-400 w-full absolute"
              style={{ height: `${(value.net / netMax) * 100}%` }}
            ></div>
            <div
              className="bg-sky-200 w-full absolute"
              style={{ height: `${(value.fwd / fwdMax) * 100}%` }}
            ></div>
          </div>
        ))}
      </div>
      <div className="self-stretch text-center text-zinc-500 text-xs font-normal">
        7 Day Power Utilization
      </div>
    </div>
  );
};
