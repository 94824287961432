import { Dayjs, dayjs } from "utils/dayjs";

export const formatDate = (day: Dayjs) => {
  return day.format("YYYY-MM-DD HH:mm:ss.SSS");
};

const getBaseURL = () => {
  switch (process.env.REACT_APP_ENV) {
    case "production":
      return "https://data.spectra.api.aerovy.com";
    case "staging":
      return "https://data.spectra.api.staging.aerovy.com";
    case "development":
      return "https://data.spectra.api.dev.aerovy.com";
    default:
      return "/data";
  }
};

export const BASE_URL = getBaseURL();

export const getHeaders = () => {
  const partnerId = localStorage.getItem("partnerId");
  return {
    Accept: "*/*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("auth0_token")}`,
  };
};

export type Datapoint = {
  type: string;
  unit: string;
  value: number;
};

export type DataResponse = {
  time: string; // in format 2024-03-19T12:00:00+00:00
  dataPoints: Datapoint[];
};

export const getDataForThing = async (
  siteId: string,
  thingId: string,
  start: Dayjs,
  end: Dayjs,
  binUnit: string = "h",
  binValue: number = 1,
): Promise<DataResponse[]> => {
  const startFmtd = formatDate(start);
  const endFmtd = formatDate(end);

  const params = [
    `startTime=${encodeURIComponent(startFmtd)}`,
    `endTime=${encodeURIComponent(endFmtd)}`,
    `binUnit=${encodeURIComponent(binUnit)}`,
    `binValue=${encodeURIComponent(binValue)}`,
  ].join("&");

  return await fetch(`${BASE_URL}/site/${siteId}/thing/${thingId}?${params}`, {
    headers: getHeaders(),
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`HTTP error, status: ${res.status}`);
    }
    return res.json();
  });
};

export const getSummaryForSite = async (
  siteId: string,
  start: Dayjs,
  end: Dayjs,
  binUnit: string = "h",
  binValue: number = 1,
): Promise<Timeseries> => {
  const startFmtd = formatDate(start);
  const endFmtd = formatDate(end);

  const params = [
    `startTime=${encodeURIComponent(startFmtd)}`,
    `endTime=${encodeURIComponent(endFmtd)}`,
    `binUnit=${encodeURIComponent(binUnit)}`,
    `binValue=${encodeURIComponent(binValue)}`,
  ].join("&");

  return await fetch(`${BASE_URL}/site/${siteId}/summary?${params}`, {
    headers: getHeaders(),
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`HTTP error, status: ${res.status}`);
    }
    return res.json();
  });
};

export const getSummaryForThings = async (
  siteId: string,
  thingIds: string[],
  start: Dayjs,
  end: Dayjs,
): Promise<DataResponse> => {
  const startFmtd = formatDate(start);
  const endFmtd = formatDate(end);

  const params = [
    `startTime=${encodeURIComponent(startFmtd)}`,
    `endTime=${encodeURIComponent(endFmtd)}`,
  ].join("&");

  return await fetch(`${BASE_URL}/site/${siteId}/things/summary?${params}`, {
    method: "POST",
    headers: {
      ...getHeaders(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(thingIds),
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`HTTP error, status: ${res.status}`);
    }
    return res.json();
  });
};

export const getTimeseriesForSite = async (
  siteId: string,
  start: Dayjs,
  end: Dayjs,
  binUnit: string = "h",
  binValue: number = 1,
): Promise<DataResponse[]> => {
  const startFmtd = formatDate(start);
  const endFmtd = formatDate(end);

  const params = [
    `startTime=${encodeURIComponent(startFmtd)}`,
    `endTime=${encodeURIComponent(endFmtd)}`,
    `binUnit=${encodeURIComponent(binUnit)}`,
    `binValue=${encodeURIComponent(binValue)}`,
  ].join("&");

  return await fetch(`${BASE_URL}/site/${siteId}/timeseries?${params}`, {
    headers: getHeaders(),
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`HTTP error, status: ${res.status}`);
    }
    return res.json();
  });
};

// convert to dict response
export type Timeseries = {
  start: string;
  end: string;
  types: string[];
  units: string[];
  values: TimeseriesValueWithStart[];
  summary: TimeseriesValue;
};

export type TimeseriesValue = {
  [key: string]: number;
};

export type TimeseriesValueWithStart = { time: string } & TimeseriesValue;

const uniqueValues = <T>(arr: T[]): T[] => {
  return Array.from(new Set(arr));
};

export const mergeDatapoints = (datapoints: Datapoint[]): TimeseriesValue => {
  return datapoints.reduce((acc, curr) => {
    acc[curr.type] = curr.value;
    return acc;
  }, {});
};

export const filterArrayOnlyUniqueKey = <T>(arr: T[], key: string): T[] => {
  let seen = new Set();
  return arr.filter((e) => {
    if (seen.has(e[key])) {
      console.warn("Duplicate timestamp", e[key]);
      return false;
    }
    seen.add(e[key]);
    return true;
  });
};

export const convertToTimeseries = (
  points: DataResponse[],
): Timeseries | null => {
  if (points.length === 0) {
    return null;
  }

  const start = points[0].time;
  const end = points[points.length - 1].time;

  const types = uniqueValues(
    points.map((p) => p.dataPoints.map((dp) => dp.type)).flat(),
  );
  const units = uniqueValues(
    points.map((p) => p.dataPoints.map((dp) => dp.unit)).flat(),
  );
  const values = filterArrayOnlyUniqueKey(points, "time")
    .map((p) => {
      const date = dayjs(p.time).toDate();
      return {
        ...mergeDatapoints(p.dataPoints),
        time: date,
      };
    })
    .flat();

  const summary = values.reduce((acc, v) => {
    // sum up all the values with the same key name
    Object.keys(v).forEach((key) => {
      acc[key] = (acc[key] || 0) + v[key];
    });
    return acc;
  }, {});

  return { start, end, types, units, values, summary };
};

export const generateIdealBinSize = (
  start: Dayjs,
  end: Dayjs,
): [number, string] => {
  // generates "ideal" bins for a given timeframe.
  // Shoot for 50-150 datapoints given the window

  const weeks = end.diff(start, "week");

  if (weeks <= 1) {
    return [1, "h"];
  } else if (weeks <= 2) {
    return [4, "h"];
  } else if (weeks <= 4) {
    return [8, "h"];
  }

  const months = end.diff(start, "month");

  if (months <= 3) {
    return [1, "d"];
  } else if (months <= 6) {
    return [2, "d"];
  }

  const years = end.diff(start, "year");

  if (years <= 1) {
    return [7, "d"];
  } else if (years <= 3) {
    return [14, "d"];
  } else {
    return [1, "m"];
  }
};
