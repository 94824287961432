import { Trend } from "components/sites/SiteTile.tsx";

const MetricCard = ({ metricCardStat }) => {
  const { value, label, units, trend } = metricCardStat;
  return (
    <div className="flex flex-col justify-start align-start">
      <div className="flex stretch justify-between align-center">
        <div className="flex justify-start items-center gap-2">
          <div className="text-title text-space50">{value ?? "-"}</div>
          <div className="text-caption text-space70">{units}</div>
        </div>
        <div className="flex items-center">
          <Trend percent={trend} />
        </div>
      </div>
      <div className="text-caption text-space70">{label}</div>
    </div>
  );
};

export default MetricCard;
