import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { dayjs } from "utils/dayjs";

import { createContext, useContext, useState, useRef, useEffect } from "react";

import { ReactComponent as ChevronDown } from "images/icons/chevron.down.svg";

const USER_TIME_ZONE_DISPLAY = dayjs().format("z");
const USER_TIME_ZONE = dayjs.tz.guess();

type SelectedTimeRangeContextType = {
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
  setStart: (start: dayjs.Dayjs) => void;
  setEnd: (end: dayjs.Dayjs) => void;
};

const SelectedTimeRangeContext = createContext<
  SelectedTimeRangeContextType | undefined
>(undefined);

export const useSelectedTimeRange = () => {
  const context = useContext(SelectedTimeRangeContext);
  if (!context) {
    throw new Error(
      "useSelectedTimeRange must be used within a SelectedTimeRangeProvider",
    );
  }
  return context;
};

export const SelectedTimeRangeProvider: React.FC = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [start, setStart] = useState(dayjs().subtract(1, "week"));
  const [end, setEnd] = useState(dayjs());

  return (
    <SelectedTimeRangeContext.Provider value={{ start, end, setStart, setEnd }}>
      {children}
    </SelectedTimeRangeContext.Provider>
  );
};

enum TimeRangeOptions {
  Past24Hours = "Past Day",
  Today = "Today",
  Yesterday = "Yesterday",
  Past7d = "Past Week",
  CustomRange = "Custom Range",
}

const getFormattedTime = (
  timeRange: TimeRangeOptions,
  start: dayjs.Dayjs,
  end: dayjs.Dayjs,
  isUTC: boolean,
) => {
  if (timeRange !== TimeRangeOptions.CustomRange) {
    return timeRange;
  }

  // create a string in format [start.day] [start.time] - [end.day]? [end.time]

  const timezone: string = isUTC ? "UTC" : USER_TIME_ZONE_DISPLAY;
  if (start.date() === end.date()) {
    return `${start.format("lll")} - ${end.format("LT")} ${timezone}`;
  }

  return `${start.format("lll")} - ${end.format("lll")} ${timezone}`;
};

export const TimeRangeSelector = () => {
  const DEFAULT_TIME_OPTION = TimeRangeOptions.Past7d;

  const { start, end, setStart, setEnd } = useSelectedTimeRange();

  const [isUTC, setIsUTC] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedTimeRange, setSelectedTimeRange] =
    useState<TimeRangeOptions>(DEFAULT_TIME_OPTION);
  const [selectedTimeRangeDisplay, setSelectedTimeRangeDisplay] =
    useState<string>(DEFAULT_TIME_OPTION);
  const menuRef = useRef<HTMLDivElement>(null);
  
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const handleClickOutside = (event: MouseEvent) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target as Node) &&
      !event.target.closest(".MuiPickersPopper-root") // Check if the click is inside the DateTimePicker
    ) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const setTimeRange = (
    timeOption: TimeRangeOptions,
    overrides: {
      isUTC?: boolean;
      autoclose?: boolean;
      start?: dayjs.Dayjs;
      end?: dayjs.Dayjs;
    } = {},
  ) => {
    const defaults = { isUTC: isUTC, autoclose: true, start: start, end: end };
    const options = Object.assign(defaults, overrides);

    setSelectedTimeRange(timeOption);

    if (options.autoclose) {
      setIsMenuOpen(false);
    }

    // update the time(s) if necessary
    const now = dayjs();
    switch (timeOption) {
      case TimeRangeOptions.Past24Hours:
        setIsUTC(false);
        setStart(now.subtract(1, "day"));
        setEnd(now);
        break;
      case TimeRangeOptions.Today:
        setIsUTC(false);
        setStart(now.startOf("day"));
        setEnd(now.endOf("day"));
        break;
      case TimeRangeOptions.Yesterday:
        setIsUTC(false);
        const yesterday = now.subtract(1, "day");
        setStart(yesterday.startOf("day"));
        setEnd(yesterday.endOf("day"));
        break;
      case TimeRangeOptions.Past7d:
        setIsUTC(false);
        setStart(now.subtract(1, "week"));
        setEnd(now);
        break;
      case TimeRangeOptions.CustomRange:
        setIsUTC(options.isUTC);

        const newStart = options.isUTC
          ? options.start.tz("UTC")
          : options.start.tz(USER_TIME_ZONE);
        const newEnd = options.isUTC
          ? options.end.tz("UTC")
          : options.end.tz(USER_TIME_ZONE);
        setStart(newStart);
        setEnd(newEnd);

        break;
    }

    // set formatted string
    const customRangeString = getFormattedTime(
      timeOption,
      options.start,
      options.end,
      options.isUTC,
    );
    setSelectedTimeRangeDisplay(customRangeString);
  };

  return (
    <div className="relative" ref={menuRef}>
      <button
        className={`px-3.5 py-2 rounded-full justify-end items-center gap-1 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue90 focus:ring-blue90 flex ${selectedTimeRange === DEFAULT_TIME_OPTION ? "border border-space80 hover:bg-gray95 text-space70" : "bg-blue50 text-white"}`}
        onClick={toggleMenu}
      >
        <div className="text-xs font-medium leading-[14px]">
          {selectedTimeRangeDisplay}
        </div>
        <div className="text-xs">
          <ChevronDown />
        </div>
      </button>
      <div
        className={`origin-top-right w-[250px] absolute right-0 py-1 mt-2 border border-gray95 rounded-md shadow-lg bg-white divide-y focus:outline-none z-20 ${isMenuOpen ? "" : "hidden"}`}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {/* Past 24h */}
          <button
            className={`px-4 w-full py-3 text-xs text-left ${selectedTimeRange === TimeRangeOptions.Past24Hours ? "bg-blue90" : "hover:bg-gray95"}`}
            onClick={() => setTimeRange(TimeRangeOptions.Past24Hours)}
          >
            Past Day
          </button>
          {/* Today */}
          <button
            className={`px-4 w-full py-3 text-xs text-left ${selectedTimeRange === TimeRangeOptions.Today ? "bg-blue90" : "hover:bg-gray95"}`}
            onClick={() => setTimeRange(TimeRangeOptions.Today)}
          >
            Today
          </button>
          {/* Yesterday */}
          <button
            className={`px-4 w-full py-3 text-xs text-left ${selectedTimeRange === TimeRangeOptions.Yesterday ? "bg-blue90" : "hover:bg-gray95"}`}
            onClick={() => setTimeRange(TimeRangeOptions.Yesterday)}
          >
            Yesterday
          </button>
          {/* Past 7d */}
          <button
            className={`px-4 w-full py-3 text-xs text-left ${selectedTimeRange === TimeRangeOptions.Past7d ? "bg-blue90" : "hover:bg-gray95"}`}
            onClick={() => setTimeRange(TimeRangeOptions.Past7d)}
          >
            Past Week
          </button>
          {/* Custom Range */}
          <div
            className={`px-4 w-full py-3 text-xs text-left ${selectedTimeRange === TimeRangeOptions.CustomRange ? "bg-blue90" : "hover:bg-gray95"}`}
          >
            <p>Custom Range</p>
            <div className="my-1">
            {
            isMenuOpen && (
              <DateTimePicker
                value={start}
                onChange={(value) => {
                  setTimeRange(TimeRangeOptions.CustomRange, {
                    autoclose: false,
                    start: value,
                  });
                }}
                maxDateTime={end}
                slotProps={{ textField: { size: "small" } }}
                closeOnSelect={true}
                timezone={isUTC ? "UTC" : "system"}
              />
            )}
            </div>
            <p className="text-xs w-fill text-center">to</p>
            <div className="my-1">
            { 
            isMenuOpen && (
              <DateTimePicker
              value={end}
              onChange={(value) => {
                setTimeRange(TimeRangeOptions.CustomRange, {
                  autoclose: false,
                  end: value,
                });
              }}
              onClose={() => setIsMenuOpen(false)}
              minDateTime={start}
              slotProps={{ textField: { size: "small" } }}
              closeOnSelect={true}
              timezone={isUTC ? "UTC" : "system"}
            />
            )}
            </div>
            <div className="inline-flex w-full divide-y mt-2 border border-gray90 rounded">
              <button
                className={`p-2 w-full text-xs ${isUTC ? "bg-blue90" : "hover:bg-gray95 bg-white"}`}
                onClick={() => {
                  setTimeRange(TimeRangeOptions.CustomRange, { isUTC: true });
                }}
              >
                UTC
              </button>
              <button
                className={`p-2 w-full text-xs ${!isUTC ? "bg-blue90" : "hover:bg-gray95 bg-white"}`}
                onClick={() => {
                  setTimeRange(TimeRangeOptions.CustomRange, { isUTC: false });
                }}
              >
                Local
              </button>
            </div>
          </div>
        </LocalizationProvider>
      </div>
    </div>
  );
};
