import { IoMdBatteryCharging } from "react-icons/io";
import { MdOutlinePower } from "react-icons/md";

const Alert = ({ type, severity, message, deviceId, timestamp, icon }) => {
  const color = severity === "critical" ? "bg-red50" : "bg-yellow50";

  return (
    <div
      className={`p-2 ${color} rounded justify-between items-start inline-flex text-white text-body`}
    >
      <div className="justify-start items-start gap-2 flex">
        <div>{icon}</div>
        <div>{message}</div>
        <div className="opacity-50">{deviceId}</div>
      </div>
      <div className="justify-end items-center gap-2 flex">{timestamp}</div>
    </div>
  );
};

export const AlertRow = ({ state, message, infoTop, infoBottom, type }) => {
  const severity = state;
  const deviceId = infoTop;
  const timestamp = infoBottom;
  const icon =
    type === "energy" ? (
      <IoMdBatteryCharging fontSize={20} />
    ) : (
      <MdOutlinePower fontSize={20} />
    );

  return (
    <Alert
      icon={icon}
      severity={severity}
      message={message}
      deviceId={deviceId}
      timestamp={timestamp}
    />
  );
};

const StatusAlert = ({ batteryAlerts = [], chargerAlerts = [] }) => {
  const alerts = [batteryAlerts, chargerAlerts]
    .flatMap((v) => Object.values(v ?? []))
    .flatMap((v) => v);
  return (
    <div className="overflow-auto h-full">
      <p className="text-space50 text-heading3 mb-4">Alerts</p>
      <div className="flex flex-col gap-2 pt-2">
        {alerts && alerts.length > 0 ? (
          alerts.map((alert, idx) => <AlertRow {...alert} key={idx} />)
        ) : (
          <div className="text-caption text-center text-space70 py-4">
            No alerts for selected timeframe
          </div>
        )}
      </div>
    </div>
  );
};

export default StatusAlert;
