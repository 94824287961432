// rexport dayjs with extensions
// solves the problem in https://github.com/iamkun/dayjs/issues/655
import dayjs, { Dayjs } from "dayjs";
import advanced from "dayjs/plugin/advancedFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);
dayjs.extend(localizedFormat);

export { dayjs, Dayjs };
