import { TimeSeries, convertToTimeseries, getDataForThing } from "api/data.ts";
import { generateIdealBinSize } from "api/data.ts";
import { getSiteDetail } from "api/ingestion/places.ts";
import { GenericAttributes, Thing } from "api/ingestion/things.ts";
import { Pin } from "components";
import { SmallHorizontalTimeseriesChart } from "components/charts/horizontalTimeSeries";
import { useSelectedTimeRange } from "context/SelectedTimeRangeContext.tsx";

import { useEffect, useState } from "react";
import Map, { Marker } from "react-map-gl";

import { ReactComponent as CloseIcon } from "images/icons/close.svg";
import { ReactComponent as SearchIcon } from "images/icons/search.svg";

const Divider = () => {
  return <div className="w-full h-px bg-gray95" />;
};

const MeterDetail = ({
  thingId,
  thingName,
  thingType,
  thingDescription,
  model,
  isSimulated,
  longitude,
  latitude,
  altitude,
  integrationId,
  partnerId,
  siteId,
  levelId,
  attributes,
  createdAt,
  createdBy,
  setSelectedDevice,
}: {
  thingId: string;
  thingName: string;
  thingType: string;
  thingDescription: string;
  model: string;
  isSimulated: boolean;
  longitude: number;
  latitude: number;
  altitude: number;
  integrationId: string;
  partnerId: string;
  siteId: string;
  levelId: string;
  attributes: GenericAttributes;
  createdAt: string;
  createdBy: string;
  setSelectedDevice: (device: Thing | null) => void;
}) => {
  const { start, end } = useSelectedTimeRange();
  const [timeseries, setTimeseries] = useState<TimeSeries | null>(undefined);
  const [siteName, setSiteName] = useState<string>("");

  useEffect(() => {
    // TODO: probably shouldnt call getSiteDetail here just for siteName
    getSiteDetail(siteId).then((site) => setSiteName(site.siteName));
  }, [siteId]);

  useEffect(() => {
    const [binValue, binUnit] = generateIdealBinSize(start, end);
    getDataForThing(siteId, thingId, start, end, binUnit, binValue)
      .then(convertToTimeseries)
      .then(setTimeseries)
      .catch((e) => {
        setTimeseries(null);
        console.error(e);
      });
  }, [siteId, thingId, start, end]);

  const alerts = [];

  const rawDetails = (
    <div className="p-4 rounded-xl border border-gray95 mt-4 flex flex-col">
      <h3 className="text-heading3 text-space50 mb-4">Raw Details</h3>
      <p className="text-caption text-space70">ID: {thingId}</p>
      <p className="text-caption text-space70">Name: {thingName}</p>
      <p className="text-caption text-space70">Type: {thingType}</p>
      <p className="text-caption text-space70">
        Description: {thingDescription}
      </p>
      <p className="text-caption text-space70">Model: {model}</p>
      <p className="text-caption text-space70">Simulated: {isSimulated}</p>
      <p className="text-caption text-space70">
        Location: {latitude}, {longitude} (altitude: {altitude})
      </p>
      <p className="text-caption text-space70">
        Integration ID: {integrationId}
      </p>
      <p className="text-caption text-space70">Partner ID: {partnerId}</p>
      <p className="text-caption text-space70">Site ID: {siteId}</p>
      <p className="text-caption text-space70">Level ID: {levelId}</p>
      <p className="text-caption text-space70">Created At: {createdAt}</p>
      <p className="text-caption text-space70">Created By: {createdBy}</p>
      <p className="text-caption text-space70">
        Attributes: {JSON.stringify(attributes)}
      </p>
    </div>
  );

  const map = (
    <div className="p-4 mt-4 w-full rounded-xl border border-gray9 flex overflow-hidden h-[300px]">
      <Map
        key={thingId}
        initialViewState={{
          longitude,
          latitude,
          zoom: 16,
        }}
        viewState={{
          width: "100%",
          height: "100%",
        }}
        mapStyle="mapbox://styles/mapbox/light-v11"
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      >
        <Marker longitude={longitude} latitude={latitude} anchor="center">
          <Pin color={"#5EA15F"} id={thingName.slice(-1)} />
        </Marker>
      </Map>
    </div>
  );

  return (
    <>
      <div className="w-full mt-2 p-4 rounded-xl border border-gray95 flex-col justify-start items-start gap-4 inline-flex">
        <div className="w-full justify-between items-center inline-flex">
          <div className="flex-col justify-start items-start inline-flex">
            <div className="text-space70 text-xs font-normal">
              {thingType} &ndash; {siteName}
            </div>
            <div className="text-space50 text-base font-medium leading-snug">
              {thingName}
            </div>

            <div className="text-space70 text-caption pt-1">
              {thingDescription}
            </div>
          </div>
          <div
            className="text-space50 text-base cursor-pointer"
            onClick={() => setSelectedDevice(null)}
          >
            <CloseIcon />
          </div>
        </div>
        <SmallAlertDisclosure alerts={alerts} />
        <PowerConsumptionChart timeseries={timeseries} />
        <Divider />
        <div className="flex flex-wrap w-full justify-start items-start gap-4">
          <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
            <div className="w-full justify-start items-center gap-2 inline-flex">
              <div className="justify-start items-start gap-1 flex">
                <div className="text-space50 text-base font-medium leading-relaxed">
                  {timeseries?.summary?.fwd?.toFixed(2) ?? "-"}
                </div>
                <div className="text-space70 text-base font-medium leading-relaxed">
                  kWh
                </div>
              </div>
              {/* <Trend percent={trend} /> */}
            </div>
            <div className="text-space70 text-[10px] font-medium tracking-wide">
              FORWARD POWER DRAW
            </div>
          </div>
          <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
            <div className="w-full justify-start items-center gap-2 inline-flex">
              <div className="justify-start items-start gap-1 flex">
                <div className="text-space50 text-base font-medium leading-relaxed">
                  {timeseries?.summary?.rev?.toFixed(2) ?? "-"}
                </div>
                <div className="text-space70 text-base font-medium leading-relaxed">
                  kWh
                </div>
              </div>
              {/* <Trend percent={trend} /> */}
            </div>
            <div className="text-space70 text-[10px] font-medium tracking-wide">
              REVERSE POWER DRAW
            </div>
          </div>
          <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
            <div className="w-full justify-start items-center gap-2 inline-flex">
              <div className="justify-start items-start gap-1 flex">
                <div className="text-space50 text-base font-medium leading-relaxed">
                  {timeseries?.summary?.net?.toFixed(2) ?? "-"}
                </div>
                <div className="text-space70 text-base font-medium leading-relaxed">
                  kWh
                </div>
              </div>
              {/* <Trend percent={trend} /> */}
            </div>
            <div className="text-space70 text-[10px] font-medium tracking-wide">
              NET POWER DRAW
            </div>
          </div>
        </div>
      </div>
      {map}
      {rawDetails}
    </>
  );
};

export default MeterDetail;

const DownstreamDevice = ({ device }: { device: Thing }) => {
  // TODO: make this dynamic when we have the data
  // TODO: make it a disclosure
  // TODO: make it link out to the correct device
  return (
    <div className="self-stretch px-4 py-2 rounded border border-gray-200 flex-col justify-start items-start flex">
      <div className="self-stretch justify-between items-center inline-flex">
        <div className="justify-start items-center gap-2 flex">
          <div className="text-space70 text-xs leading-[17px]"></div>
          <div className="flex-col justify-start items-start inline-flex">
            <div className="text-space50 text-xs font-normal">
              {device.thingName}
            </div>
            <div className="text-space70 text-[10px] font-medium">
              {/* 1293 kWh Discharged */}
              {device.thingType}
            </div>
          </div>
        </div>
        {/* <div className="w-10 justify-end items-center gap-3 flex">
          <div className="justify-start items-end flex">
            <div className="text-yellow-400 text-xs font-normal">1</div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

const SmallAlertDisclosure = ({ alerts }: { alerts: any[] }) => {
  const style = alerts.length
    ? "bg-yellow40 border-yellow50 text-white"
    : "bg-gray95 border-gray90 text-space50";
  // TODO: make it actually a disclosure and show alert details
  return (
    <div
      className={`w-full px-3 py-2 rounded-sm border ${style} justify-between items-center inline-flex`}
    >
      <div className="text-xs font-normal">
        {alerts.length} Active Alert{alerts.length === 1 ? "" : "s"}
      </div>
    </div>
  );
};

const PowerConsumptionChart = ({ timeseries }: { timeseries: Timeseries }) => {
  if (timeseries === undefined) {
    return <p className="text-space50 text-caption">Loading...</p>;
  }

  if (timeseries === null) {
    return <p className="text-space50 text-caption">No Data</p>;
  }

  return <SmallHorizontalTimeseriesChart timeseries={timeseries} />;

  const loaded = timeseries.length > 0;
  const maxForward = Math.max(...timeseries.map((t) => t.fwd));
  const maxReverse = Math.max(...timeseries.map((t) => t.rev));
  const max = maxForward + maxReverse;
  return (
    <div className="self-stretch flex-col justify-start items-start gap-2.5 flex">
      <div
        className={`self-stretch justify-start items-start gap-0.5 inline-flex transition-[height] overflow-hidden ${loaded ? "h-[150px]" : "h-0"}`}
      >
        {timeseries.map((timeseries, i) => (
          <div
            key={i}
            className="grow shrink basis-0 h-[150px] flex-col justify-start items-start inline-flex"
          >
            <div className="self-stretch grow shrink basis-0 bg-blue95" />
            <div
              className="self-stretch bg-blue70 w-full"
              style={{ height: `${(timeseries.net / max) * 100}%` }}
            />
            <div
              className="self-stretch bg-blue60 w-full"
              style={{ height: `${(timeseries.fwd / max) * 100}%` }}
            />
            <div
              className="self-stretch bg-blue50 w-full"
              style={{ height: `${(timeseries.rev / max) * 100}%` }}
            />
          </div>
        ))}
        {/* <div className="grow shrink basis-0 h-[150px] flex-col justify-start items-start inline-flex">
          <div className="self-stretch grow shrink basis-0 bg-yellow90" />
          <div className="self-stretch h-[34px] bg-yellow70" />
          <div className="self-stretch h-[75px] bg-yellow50" />
        </div> */}
      </div>
      <div className="text-space70 text-[10px] font-medium tracking-wide">
        POWER DRAW
      </div>
    </div>
  );
};
