import {
  ComposedChart,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

function createRangeArray(range) {
  const rangeArray = [];
  const diff = Math.max(1, Math.floor(range / 24));
  // Ensure a <= b for the loop to work correctly
  for (let i = 0; i <= range; i += diff) {
    rangeArray.push(i);
  }
  return rangeArray;
}

export const RawChart = ({
  data,
  labels = ["Usage (kW)", "Grid Draw (kW)"],
  colors = ["#f2aa3c", "#ab5fb3"],
  dataKeys = ["discharged", "drawn"],
}) => {
  const maxY = Math.floor(
    Math.max(...dataKeys.map((k) => Math.max(...data.map((v) => +v[k])))) * 1.1,
  );
  const upperBound = Math.max(...data.map((v) => +v.time));
  return (
    <>
      <ResponsiveContainer width="100%" aspect={3}>
        <ComposedChart margin={{ right: 10 }}>
          <XAxis
            type="number"
            stroke="#b4b4b4"
            dataKey="time"
            domain={[0, upperBound]}
            interval={0}
            axisLine={false}
            tickLine={false}
            ticks={createRangeArray(upperBound)}
          />

          <YAxis type="number" width={30} stroke="#b4b4b4" domain={[0, maxY]} />

          {labels.map((label, index) => (
            <Line
              key={index}
              name={label}
              type="number"
              data={data}
              dataKey={dataKeys[index]}
              stroke={colors[index]}
              strokeWidth={2}
              dot={false}
            />
          ))}

          <ReferenceLine x={0} strokeWidth={2} />
          <ReferenceLine y={0} />
        </ComposedChart>
      </ResponsiveContainer>

      <div class="p-2 flex justify-center items-center gap-1">
        {labels.map((label, index) => (
          <div
            key={index}
            className="px-3 py-2 text-caption text-space50 border-t-4"
            style={{ borderColor: colors[index] }}
          >
            {label}
          </div>
        ))}
      </div>
    </>
  );
};

const ChargerPowerChart = ({ data }) => {
  return (
    <div className="elevation-1 flex flex-col rounded-md bg-white bg-white p-4">
      <p className="text-space50 text-heading3 mb-4">Energy Utilization</p>
      <RawChart data={data} />
    </div>
  );
};

export default ChargerPowerChart;
