import { Thing } from "api/ingestion/things.ts";
import {
  BatterySummaryStats,
  ChargerSummaryStats,
  getAlertsForBattery,
  getAlertsForCharger,
  getBatterySummaryStats,
  getBatteryTimeseries,
  getChargerSummaryStats,
  getChargerTimeseries,
} from "api/timeseries";
import { MeterDetail, Pin, StatusAlert } from "components";
import MetricCard from "components/alerts/MetricCard";
import { RawChart } from "components/charts/ChargerPowerChart";
import { useSelectedTimeRange } from "context/SelectedTimeRangeContext.tsx";

import { useEffect, useState } from "react";
import Map, { Marker } from "react-map-gl";

const DeviceDetail = ({
  selectedDevice,
  setSelectedDevice = () => {},
}: {
  selectedDevice: Thing;
  setSelectedDevice?: (device: Thing | null) => void;
}) => {
  const isMeter = selectedDevice?.thingType == "Meter"; // TODO: this should be more robust
  return (
    <div
      className={`origin-right pr-6 h-screen overflow-y-auto bg-white lg:relative transition-transform drop-shadow-xl lg:drop-shadow-none ${selectedDevice ? "translate-x-0 lg:ml-4 px-2 right-0 scale-x-100 min-w-[300px] lg:w-1/4 z-20" : "pl-4 translate-x-full scale-x-0 w-0"}`}
    >
      {selectedDevice && !isMeter && (
        <ChargerOrBatteryDetails
          {...selectedDevice}
          setSelectedDevice={setSelectedDevice}
        />
      )}
      {selectedDevice && isMeter && (
        <MeterDetail
          {...selectedDevice}
          setSelectedDevice={setSelectedDevice}
        />
      )}
    </div>
  );
};

export default DeviceDetail;

const ChargerOrBatteryDetails = ({
  siteId,
  thingType,
  thingName,
  longitude,
  latitude,
  model,
  setSelectedDevice,
}: {
  siteId: string;
  thingType: string;
  thingName: string;
  longitude: number;
  latitude: number;
  model: string;
  setSelectedDevice: (device: Thing | null) => void;
}) => {
  const isCharger = thingType == "Charger"; // TODO: this should be more robust
  const { start, end } = useSelectedTimeRange();
  const [alerts, setAlerts] = useState([]);
  const [stats, setStats] = useState({});
  const [timeseriesData, setTimeseriesData] = useState([]);

  useEffect(() => {
    const fetchAlerts = async () => {
      if (isCharger) {
        const alerts = await getAlertsForCharger(thingName, siteId, start, end);
        setAlerts(alerts);
      } else {
        const alerts = await getAlertsForBattery(thingName, siteId, start, end);
        setAlerts(alerts);
      }
    };
    const fetchStats = async () => {
      if (isCharger) {
        const stats = await getChargerSummaryStats(
          thingName,
          siteId,
          start,
          end,
        );
        setStats(stats);
      } else {
        const stats = await getBatterySummaryStats(
          thingName,
          siteId,
          start,
          end,
        );
        setStats(stats);
      }
    };

    const fetchTimeseries = async () => {
      if (isCharger) {
        getChargerTimeseries(thingName, siteId, start, end)
          .then(setTimeseriesData)
          .catch(console.error);
      } else {
        getBatteryTimeseries(thingName, siteId, start, end)
          .then(setTimeseriesData)
          .catch(console.error);
      }
    };

    fetchAlerts();
    fetchStats();
    fetchTimeseries();
  }, [siteId, thingName, start, end, isCharger]);

  const closeButton = (
    <span
      onClick={() => setSelectedDevice(null)}
      className="text-blue50 float-right cursor-pointer"
    >
      &times;
    </span>
  );

  const statsView = isCharger ? (
    <div className="p-4 elevation-1 rounded-md flex flex-col gap-4">
      <h3 className="text-heading3 text-space50">Stats</h3>
      <div className="grid grid-cols-2 gap-4">
        <MetricCard
          metricCardStat={{
            value: (stats as ChargerSummaryStats).dispensed,
            label: "Discharged",
            units: "kWh",
            trend: 0,
          }}
        />
        <MetricCard
          metricCardStat={{
            value: (stats as ChargerSummaryStats).scheduled,
            label: "Scheduled",
            units: "kWh",
            trend: 0,
          }}
        />
        <MetricCard
          metricCardStat={{
            value: (stats as ChargerSummaryStats).possible,
            label: "Possible",
            units: "kWh",
            trend: 0,
          }}
        />
        <MetricCard
          metricCardStat={{
            value: (stats as ChargerSummaryStats).drawn,
            label: "Drawn",
            units: "kWh",
            trend: 0,
          }}
        />
      </div>
    </div>
  ) : (
    <div className="p-4 elevation-1 rounded-md flex flex-col gap-4">
      <h3 className="text-heading3 text-space50">Stats</h3>
      <div className="grid grid-cols-2 gap-4">
        <MetricCard
          metricCardStat={{
            value: (stats as BatterySummaryStats).stored,
            label: "Stored",
            units: "kWh",
            trend: 0,
          }}
        />
        <MetricCard
          metricCardStat={{
            value: (stats as BatterySummaryStats).health,
            label: "Health",
            units: "% SoH",
            trend: 0,
          }}
        />
        <MetricCard
          metricCardStat={{
            value: (stats as BatterySummaryStats).capacity,
            label: "Capacity",
            units: "kWh",
            trend: 0,
          }}
        />
      </div>
    </div>
  );
  const alertsView = (
    <div className="p-4 elevation-1 rounded-md flex flex-col gap-4">
      <StatusAlert batteryAlerts={alerts} chargerAlerts={[]} />
    </div>
  );
  const map = (
    <div className="p-4 w-full elevation-1 rounded-md flex overflow-hidden h-[300px]">
      {latitude} {longitude}
      <Map
        initialViewState={{
          longitude,
          latitude,
          zoom: 12,
        }}
        viewState={{
          width: "100%",
          height: "100%",
        }}
        mapStyle="mapbox://styles/mapbox/light-v11"
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      >
        <Marker longitude={longitude} latitude={latitude} anchor="center">
          <Pin color={"#5EA15F"} id={thingName.slice(-1)} />
        </Marker>
      </Map>
    </div>
  );
  const utilizationChart = (
    <div className="p-4 elevation-1 rounded-md flex flex-col gap-4">
      <h3 className="text-heading3 text-space50">Utilization Chart</h3>
      {isCharger ? (
        <RawChart data={timeseriesData} />
      ) : (
        <RawChart
          data={timeseriesData}
          labels={["Stored (kWh)", "SoH (%)"]}
          dataKeys={["stored", "health"]}
        />
      )}
    </div>
  );

  const rawDetails = (
    <div className="p-4 elevation-1 rounded-md flex flex-col">
      <h3 className="text-heading3 text-space50 mb-4">Raw Details</h3>
      <p className="text-caption text-space70">Site: {siteId}</p>
      <p className="text-caption text-space70">
        Location: {latitude}, {longitude}
      </p>
      <p className="text-caption text-space70">Type Name: {thingType}</p>
      <p className="text-caption text-space70">Name: {thingName}</p>
      <p className="text-caption text-space70">Model: {model}</p>
    </div>
  );

  return (
    <div className="flex flex-col gap-4 py-4">
      <div>
        <h1 className="text-heading1 text-space50">
          Device / {thingName} {closeButton}
        </h1>
      </div>
      {statsView}
      {alertsView}
      {map}
      {utilizationChart}
      {/* {timeline} */}
      {rawDetails}
    </div>
  );
};
