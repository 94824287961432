import { Pin } from "components";

import Map, { Marker } from "react-map-gl";

const NetworkMap = ({
  latitude,
  longitude,
  chargerDevices,
  batteryDevices,
  meterDevices,
}) => {
  const Markers = (devices, color) => {
    return devices.map((device) => (
      <Marker
        longitude={device.longitude}
        latitude={device.latitude}
        anchor="center"
        key={device.thingId}
      >
        <Pin color={color} id={device.thingName.slice(-1)} />
      </Marker>
    ));
  };

  if (!latitude || !longitude) {
    return null;
  }

  return (
    <div className="flex-1 bg-white rounded-xl overflow-hidden h-full">
      <Map
        key={`${latitude}-${longitude}`}
        initialViewState={{
          longitude: longitude,
          latitude: latitude,
          zoom: 13,
        }}
        mapStyle="mapbox://styles/mapbox/light-v11"
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      >
        {Markers(chargerDevices, "#5EA15F")}
        {Markers(batteryDevices, "#6db5d1")}
        {Markers(meterDevices, "#6db5d1")}
      </Map>
    </div>
  );
};

export default NetworkMap;
