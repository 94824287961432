// Using this to pull out the code that is being used to pull the data from the API
// This is a temporary file and will be removed once the API is ready the components are cleaned up
import { fetchFromTimestream } from "api";
import { Dayjs, dayjs } from "utils/dayjs";

import { NO_DATA_ROW, formatDate } from "./utils";

export const getAlertsForBattery = (
  deviceName: string, // use attributes.FriendlyName
  siteId: string,
  start: Dayjs,
  end: Dayjs,
): Promise<[]> => {
  const formattedDayStart = formatDate(start.startOf("day"));
  const partnerId = localStorage.getItem("partnerId");
  const table = `"${partnerId}"."${siteId}_Data"`;
  const timeStart = formatDate(start);
  const timeEnd = formatDate(end);

  return fetchFromTimestream(
    `SELECT
        time,
        date_diff('minute', '${formattedDayStart}', time) / 60.0,
        status
    FROM ${table}
    WHERE status != 0
    AND DeviceName = '${deviceName}'
    AND time BETWEEN '${timeStart}' AND '${timeEnd}'`,
  )
    .then((data) => data.rows)
    .then((rows) =>
      rows.map((alert) => {
        const [time, x, status] = alert.data.map((value) => value.scalarValue);

        const state =
          status === "1" ? "warning" : status === "2" ? "critical" : "";
        const message =
          status === "1"
            ? "Energy Demand Approaching Supply"
            : status === "2"
              ? "Energy Demand Exceeds Supply"
              : "";

        return {
          type: "energy",
          state: state,
          message: message,
          infoTop: deviceName,
          infoBottom: formatDate(dayjs(time)),
          x: x,
        };
      }),
    );
};

export type BatterySummaryStats = {
  stored: number;
  health: number;
  capacity: number;
};

export const getBatterySummaryStats = (
  deviceName: string, // use attributes.FriendlyName
  siteId: string,
  start: Dayjs,
  end: Dayjs,
): Promise<BatterySummaryStats> => {
  const partnerId = localStorage.getItem("partnerId");
  const table = `"${partnerId}"."${siteId}_Data"`;
  const timeStart = formatDate(start);
  const timeEnd = formatDate(end);

  return fetchFromTimestream(
    `SELECT
        MAX(soc) as capacity,
        AVG(soc) as stored,
        AVG(soh) as health
    FROM ${table}
    WHERE DeviceName = '${deviceName}'
    AND time BETWEEN '${timeStart}' AND '${timeEnd}'
    LIMIT 1`,
  )
    .then((data) => data.rows[0]?.data ?? NO_DATA_ROW)
    .then(([capacity, stored, health]) => {
      const scale = 100;
      return {
        stored: Math.floor(stored.scalarValue * scale),
        health: Math.floor(health.scalarValue),
        capacity: Math.floor(capacity.scalarValue * scale),
      };
    });
};

export type BatteryTimeseries = {
  time: number;
  stored: number;
  health: number;
};

export const getBatteryTimeseries = (
  deviceName: string, // use attributes.FriendlyName
  siteId: string,
  start: Dayjs,
  end: Dayjs,
): Promise<BatteryTimeseries[]> => {
  const partnerId = localStorage.getItem("partnerId");
  const table = `"${partnerId}"."${siteId}_Data"`;
  const timeStart = formatDate(start);
  const timeEnd = formatDate(end);

  return fetchFromTimestream(
    `SELECT
        date_diff('minute', '${timeStart}', time) / 60.0,
        soc,
        soh
    FROM ${table}
    WHERE DeviceName = '${deviceName}'
    AND time BETWEEN '${timeStart}' AND '${timeEnd}'`,
  )
    .then((data) => data.rows ?? [])
    .then((rows) => {
      return rows.map(({ data }) => {
        const [x, stored, health] = data;
        return {
          time: x.scalarValue,
          stored: stored.scalarValue,
          health: health.scalarValue,
        };
      });
    });
};
