
import { SiteLevel } from "api/ingestion/places.ts";

import { createContext, useContext, useState } from "react";

type SelectedSiteLevelContextType = {
  selectedSiteLevel: SiteLevel | null;
  setSelectedSiteLevel: (SiteLevel: SiteLevel | null) => void;
};

const SelectedSiteLevelContext = createContext<
  SelectedSiteLevelContextType | undefined
>(undefined);

export const useSelectedSiteLevel = () => {
  const context = useContext(SelectedSiteLevelContext);
  if (!context) {
    throw new Error(
      "useSelectedSiteLevel must be used within a SelectedSiteLevelProvider",
    );
  }
  return context;
};

export const SelectedSiteLevelProvider: React.FC = ({ children }) => {
  const [selectedSiteLevel, setSelectedSiteLevel] = useState<SiteLevel | null>(
    null,
  );

  return (
    <SelectedSiteLevelContext.Provider
      value={{ selectedSiteLevel, setSelectedSiteLevel }}
    >
      {children}
    </SelectedSiteLevelContext.Provider>
  );
};
