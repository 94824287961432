let baseUrl;

switch (process.env.REACT_APP_ENV) {
  case "production":
    baseUrl = "https://data.spectra.api.aerovy.com/data/test/";
    break;
  case "staging":
    baseUrl = "https://data.spectra.api.staging.aerovy.com/data/test/";
    break;
  case "development":
    baseUrl = "https://data.spectra.api.dev.aerovy.com/data/test/";
    break;
  default:
    baseUrl = "/data/test/";
}

export async function fetchFromTimestream(query) {
  const bodyData = JSON.stringify(query);

  try {
    const response = await fetch(baseUrl, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth0_token")}`,
      },
      body: bodyData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error, status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Fetch Error:", error);
    throw error;
  }
}
