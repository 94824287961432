import {
  Timeseries,
  convertToTimeseries,
  generateIdealBinSize,
  getTimeseriesForSite,
} from "api/data.ts";
import { SiteResponse, getSiteDetail } from "api/ingestion/places.ts";
import { getThingsFromSite } from "api/ingestion/things.ts";
import {
  DeviceDetail,
  EnergyCard,
  NetworkMap,
  SkyportsChargerStatus,
  StatusAlert,
} from "components";
import { HorizontalTimeseriesChart } from "components/charts/horizontalTimeSeries.tsx";
import {
  SelectedDeviceProvider,
  useSelectedDevice,
} from "context/SelectedDeviceContext.tsx";
import {
  SelectedSiteLevelProvider,
  useSelectedSiteLevel,
} from "context/SelectedSiteLevelContext.tsx";
import {
  SelectedTimeRangeProvider,
  TimeRangeSelector,
  useSelectedTimeRange,
} from "context/SelectedTimeRangeContext.tsx";

import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";

import FilteredDevices from "./filteredDevices.tsx";
import { LevelGroupDetails } from "./levelGroups.tsx";
import { TopologyOverview } from "./topology.tsx";

const Tile = ({
  className = "",
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <div
      className={`bg-white rounded-md shadow border border-gray90 ${className}`}
    >
      {children}
    </div>
  );
};

const DeviceDetailWrapper = () => {
  // allows the use of the selected device context
  const { selectedDevice, setSelectedDevice } = useSelectedDevice();
  return (
    <DeviceDetail
      selectedDevice={selectedDevice}
      setSelectedDevice={setSelectedDevice}
    />
  );
};

const PageContent = ({ siteId }: { siteId: string }) => {
  const [site, setSite] = useState<SiteResponse | null>(null);

  // TODO: Hooks need to be cleaned up as well as all of data pulling
  const { start, end } = useSelectedTimeRange();

  const [dataMarkers, setDataMarkers] = useState([0, 0]);

  const [batteryDevices, setBatteryDevices] = useState([]);
  const [chargerDevices, setChargerDevices] = useState([]);
  const [meterDevices, setMeterDevices] = useState([]);

  const [chargerSummaryStats, setChargerSummaryStats] = useState({});
  const [batterySummaryStats, setBatterySummaryStats] = useState({});

  const [gridDrawnSum, setGridDrawnSum] = useState(null);

  const [chargerAlerts, setChargerAlerts] = useState({});
  const [batteryAlerts, setBatteryAlerts] = useState({});

  const [finalStorageData, setFinalStorageData] = useState([]);
  const [finalGenerationData, setFinalGenerationData] = useState([]);
  const [finalUsageData, setFinalUsageData] = useState([]);

  const startOfDay = start.startOf("day");
  const endOfRequestMarker = end.diff(startOfDay, "hour", true);

  const [allMeterTimeseries, setAllMeterTimeseries] =
    useState<Timeseries | null>(undefined);

  const lowerHourBound = 0;
  const upperHourBound =
    24 + 24 * Math.floor(end.endOf("day").diff(start, "hour", true) / 24);

  // TODO: Data pulling has no dependencies so needs to be seperated out
  useEffect(() => {
    // Puling all events from API

    getSiteDetail(siteId)
      .then(setSite)
      .catch((err) => console.log(err));

    const [binValue, binUnit] = generateIdealBinSize(start, end);
    getTimeseriesForSite(siteId, start, end, binUnit, binValue)
      .then(convertToTimeseries)
      .then(setAllMeterTimeseries)
      .catch(console.error);

    // TODO: remove this
    // TODO: this should probably be a service instead of raw api calls
    getThingsFromSite(siteId)
      .then((devices) => {
        const batteries = devices.filter(
          (device) => device?.thingType == "Battery",
        );
        const chargers = devices.filter(
          (device) => device?.thingType == "Charger",
        );
        const meters = devices.filter((device) => device?.thingType == "Meter");
        setBatteryDevices(batteries);
        setChargerDevices(chargers);
        setMeterDevices(meters);

        // load meter timeseries

        // code below this line needs to be revalidated

        getFinalStorageData(siteId, start, end)
          .then((data) => {
            setFinalStorageData(data);
            if (data.length === 0) {
              setDataMarkers([0, 0]);
            } else {
              setDataMarkers([data[0]?.x, data[data?.length - 1]?.x]);
            }
          })
          .catch(console.error);

        getFinalGenerationData(siteId, start, end)
          .then(setFinalGenerationData)
          .catch(console.error);

        getFinalUsageData(siteId, start, end)
          .then(setFinalUsageData)
          .catch(console.error);

        let chargerSummaryStats = {};
        let gridDrawnSum = null;
        const fetchAndStoreChargerSummaryStats = async (
          chargerName,
          summaryDict,
        ) => {
          try {
            const summary = await getChargerSummaryStats(
              chargerName,
              siteId,
              start,
              end,
            );
            summaryDict[chargerName] = summary;
            gridDrawnSum = (gridDrawnSum ?? 0) + summary.drawn;
          } catch (err) {
            console.log(err);
          }
        };
        const chargerSummaryPromises = chargers.map((charger) =>
          fetchAndStoreChargerSummaryStats(
            charger?.thingName,
            chargerSummaryStats,
          ),
        );

        let batterySummaryStats = {};
        const fetchAndStoreBatterySummaryStats = async (
          batteryName,
          summaryDict,
        ) => {
          try {
            summaryDict[batteryName] = await getBatterySummaryStats(
              batteryName,
              siteId,
              start,
              end,
            );
          } catch (err) {
            console.log(err);
          }
        };
        const batterySummaryPromises = batteries.map((battery) =>
          fetchAndStoreBatterySummaryStats(
            battery?.thingName,
            batterySummaryStats,
          ),
        );

        let chargerAlerts = {};
        const fetchAndStoreChargerAlerts = async (chargerName, alertsDict) => {
          try {
            alertsDict[chargerName] = await getAlertsForCharger(
              chargerName,
              siteId,
              start,
              end,
            );
          } catch (err) {
            console.log(err);
          }
        };
        const chargerAlertsPromises = chargers.map((charger) =>
          fetchAndStoreChargerAlerts(charger?.thingName, chargerAlerts),
        );

        let batteryAlerts = {};
        const fetchAndStoreBatteryAlerts = async (batteryName, alertsDict) => {
          try {
            alertsDict[batteryName] = await getAlertsForBattery(
              batteryName,
              siteId,
              start,
              end,
            );
          } catch (err) {
            console.log(err);
          }
        };
        const batteryAlertsPromises = batteries.map((battery) =>
          fetchAndStoreBatteryAlerts(battery?.thingName, batteryAlerts),
        );

        Promise.all(chargerSummaryPromises).then(() => {
          setChargerSummaryStats(chargerSummaryStats);
          setGridDrawnSum(gridDrawnSum);
        });

        Promise.all(batterySummaryPromises).then(() => {
          setBatterySummaryStats(batterySummaryStats);
        });

        Promise.all(chargerAlertsPromises).then(() => {
          setChargerAlerts(chargerAlerts);
        });

        Promise.all(batteryAlertsPromises).then(() => {
          setBatteryAlerts(batteryAlerts);
        });
      })
      .catch((err) => console.log(err));
  }, [start, end, siteId]);

  // Assuming all batteries same size of 2000kWh

  const stored = Object.keys(batterySummaryStats)
    .map((battery) => batterySummaryStats[battery]?.stored)
    .reduce((a, b) => a + b, 0);
  const dispensedSum = Object.keys(chargerSummaryStats)
    .map((key) => chargerSummaryStats[key]?.dispensed)
    .reduce((a, b) => a + b, 0);
  const forecasted = Math.max(
    0,
    Math.floor(
      (dispensedSum / end.diff(start, "hour", true)) *
        (24 - end.diff(start, "hour", true)),
    ),
  );

  const energyCardStats = [
    {
      value: allMeterTimeseries?.summary?.net?.toFixed(0),
      units: "kWh",
      label: "Drawn from Grid",
      trend: 0,
    },
    {
      value: Object.keys(chargerSummaryStats).length > 0 ? dispensedSum : null,
      units: "kWh",
      label: "Dispensed to Vehicles",
      trend: 0,
    },
    {
      value: Object.keys(batterySummaryStats).length > 0 ? stored : null,
      units: "kWh",
      label: "Currently Stored",
      trend: 0,
    },
    {
      value: Object.keys(chargerSummaryStats).length > 0 ? forecasted : null,
      units: "kWh",
      label: "Forecasted remaining Demand",
      trend: 0,
    },
  ];

  const finalNetData = finalStorageData
    ?.map((currentData, index, array) => {
      if (index === 0) return { x: 0, y: 0 };

      let timeDifference = currentData.x;
      let netEnergyFlow = currentData.y - array[index - 1].y;

      return { x: timeDifference, y: netEnergyFlow };
    })
    .slice(0, -1);

  // Can flow from prev 3 stats combined
  // const finalNetData = netData.filter(point => parseFloat(point.x) > lowerHourBound && parseFloat(point.x) < upperHourBound);

  // TODO: Aggregate the energy data to calculate the energy card stats
  // No grid energy drawn in raw battery stats, aggregate may not be pulled from events at all

  // TODO: For this demo, we can also calculate the chargerStates from aggregated energy data, dispensed coming from finalUsageData and scheduled remaining from schedule

  const alerts = (
    <StatusAlert batteryAlerts={batteryAlerts} chargerAlerts={chargerAlerts} />
  );

  const stats = <EnergyCard energyCardStats={energyCardStats} />;

  const chargerStatus = (
    <SkyportsChargerStatus
      chargers={chargerDevices}
      batteries={batteryDevices}
      meters={meterDevices}
    />
  );

  const gridEnergyDraw = (
    <HorizontalTimeseriesChart timeseries={allMeterTimeseries} />
  );

  if (!site) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex h-full">
      <div className="grow h-screen overflow-y-scroll">
        <div className="flex pt-4 pb-5 justify-between">
          <p className="text-heading1 text-space50">
            <NavLink to="/sites">Sites</NavLink> / {site.siteName}
          </p>
          <div className="flex gap-2">
            <TimeRangeSelector />
          </div>
        </div>

        <div className="flex space-x-4 mb-4 h-[300px]">
          <Tile className="w-1/3 p-4">{alerts}</Tile>
          <Tile className="w-1/3">{stats}</Tile>
          <Tile className="w-1/3">
            <NetworkMap
              {...{
                latitude: site.latitude,
                longitude: site.longitude,
                chargerDevices,
                batteryDevices,
                meterDevices,
              }}
            />
          </Tile>
        </div>

        <div className="flex space-x-4 mb-4">
          <Tile className="w-3/4">{gridEnergyDraw}</Tile>
          <Tile className="w-1/4">
            <LevelGroupDetails site={site} />
          </Tile>
        </div>

        <SelectedSiteLevelProvider>
          <SiteTopology siteId={site.siteId} />
        </SelectedSiteLevelProvider>

        <div className="flex space-x-4 mb-4">
          <Tile className="w-full">{chargerStatus}</Tile>
        </div>
      </div>

      <DeviceDetailWrapper />
    </div>
  );
};

const SiteDetail = () => {
  const { siteId } = useParams();
  return (
    <SelectedTimeRangeProvider>
      <SelectedDeviceProvider>
        <PageContent siteId={siteId} />
      </SelectedDeviceProvider>
    </SelectedTimeRangeProvider>
  );
};

export default SiteDetail;

const SiteTopology = ({ siteId }: { siteId: string }) => {
  const { selectedSiteLevel } = useSelectedSiteLevel();
  return (
    <div className="flex space-x-4 mb-4">
      <Tile className="flex-grow">
        <TopologyOverview siteId={siteId} />
      </Tile>
      {selectedSiteLevel && (
        <Tile className="w-1/3">
          <FilteredDevices siteId={siteId} />
        </Tile>
      )}
    </div>
  );
};
