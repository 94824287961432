import { useAuth0 } from "@auth0/auth0-react";

import { default as earth } from "images/earth.jpg";

const Login = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div
      className="flex h-screen w-screen flex-col items-end justify-end bg-black"
      style={{
        backgroundImage: `url(${earth})`,
        backgroundSize: "cover",
        backgroundPosition: "center bottom",
      }}
    >
      <div className="mt-0 flex min-h-full w-full flex-col bg-white px-5 py-6 md:mt-6 md:w-1/3">
        <div className="flex flex-col px-5 py-6">
          <div className="mb-10 mt-4 flex items-center justify-center md:mt-14">
            <img
              src={require("images/sidebar_logo.png")}
              alt="Aerovy logo"
              className="h-[30px] "
            />
          </div>

          <button
            onClick={() => loginWithRedirect()}
            className="border-blue40 bg-blue50 hover:bg-blue60 elevation-2 mb-3 items-center justify-center gap-2 rounded border px-4 py-2.5 font-bold text-white"
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export { Login };
