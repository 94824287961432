export const header = 'Charger'
export const chargerType = 'Electro.Aero 240kWh'
export const displayProps = {
  "Live Utilization": <><p className="font-bold mr-1">120 kWH</p>/ 240kWh</>,
  "Connectors in Use": <><p className="font-bold mr-1">1</p>/ 2</>,
  "Available Connectors": <><p className="font-bold mr-1 text-orange-400">1</p>/ 2</>,
}

export const alertEvents = [
  ['Faulted', '08/12/23 05:23', 'Short subhead'],
  ['Faulted', '08/12/23 05:23', 'Short subhead'],
  ['Communication', '08/12/23 05:23', 'Communication with network failed'],
]

export const chargeEvents = [
  ["VT1", "VT1", "07:01", "PAO | Palo Alto, CA", 32, 32, 32],
  ["VT2", "VT2", "07:02", "LGB | Long Beach, CA", 12, 12, 12],
  ["VT3", "VT3", "07:03", "SMX | Santa Maria, CA", 23, 23, 23],
  ["VT4", "VT4", "07:04", "SCK | Stockton, CA", 36, 36, 36],
  ["VT5", "VT5", "07:05", "LGB | Long Beach, CA", 62, 62, 62],
  ["VT6", "VT6", "07:06", "PAO | Palo Alto, CA", 72, 72, 72],
  ["VT7", "VT7", "07:07", "SMX | Santa Maria, CA", 43, 43, 43],
  ["VT8", "VT8", "07:08", "SMX | Santa Maria, CA", 21, 21, 21],
]

export const feeData = [
  { time: "Jan", value: 263 },
  { time: "Feb", value: 175 },
  { time: "Mar", value: 312 },
  { time: "Apr", value: 143 },
  { time: "May", value: 398 },
  { time: "Jun", value: 204 },
  { time: "Jul", value: 327 },
  { time: "Aug", value: 129 },
  { time: "Sep", value: 216 },
  { time: "Oct", value: 182 },
  { time: "Nov", value: 315 },
  { time: "Dec", value: 258 }
];

export const energyData = [
  { year: 2010, A: 40, B: 68, C: 26, D: 22 },
  { year: 2011, A: 65, B: 33, C: 45, D: 70 },
  { year: 2012, A: 55, B: 78, C: 63, D: 60 },
  { year: 2013, A: 33, B: 44, C: 77, D: 29 },
  { year: 2014, A: 50, B: 72, C: 58, D: 37 },
  { year: 2015, A: 78, B: 30, C: 72, D: 61 },
  { year: 2016, A: 25, B: 58, C: 55, D: 26 },
  { year: 2017, A: 60, B: 39, C: 35, D: 44 },
  { year: 2018, A: 47, B: 62, C: 66, D: 75 },
  { year: 2019, A: 22, B: 54, C: 47, D: 57 },
];

export const chargerStatuses = {
  'Unavailable': 15,
  'Unused': 23,
  'Charging': 62,
}

export const chargerMap = {
  'LBG': 'Dubai International Airport Long Beach, CA',
  'PAO': 'Palm Jumeriah Palo Alto, CA',
  'CDG': 'Charles de Gaulle Airport Paris, France'
}