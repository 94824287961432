import { useAuth0 } from "@auth0/auth0-react";
import { Sidebar } from "components";
import "mapbox-gl/dist/mapbox-gl.css";
import {
  DevicesPage,
  LocationSelection,
  Login,
  NoAccessPage,
  ResponsePage,
  ScopeSelection,
  Settings,
  SiteDetail,
  ThirdParty,
} from "pages";

import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

const NonProdEnvironmentWarning = () => {
  const env = process.env.REACT_APP_ENV;

  if (env === "production") {
    return null;
  }

  if (env === "staging") {
    return (
      <div className="fixed bottom-0 text-center w-full text-space80 p-2">
        Staging Environment - Demo Use Only
      </div>
    );
  }

  return (
    <div className="fixed bottom-0 text-center w-full text-space80 p-2">
      Dev Environment - Demo Use Only
    </div>
  );
};

const App = () => {
  const { isLoading, user, isAuthenticated, getAccessTokenSilently } =
    useAuth0();

  const [auth0Token, setAuth0Token] = useState(null);

  useEffect(() => {
    const getTokens = async () => {
      const dataToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: "organize",
          scope:
            "read:data_places read:data_things read:ingest_places read:ingest_things write:ingest_places write:ingest_things read:ingest_integrations write:ingest_integrations read:ingest write:ingest read:ingest_admin write:ingest_admin",
        },
      });

      localStorage.setItem("auth0_token", dataToken);
      localStorage.setItem("partnerId", user.partnerId);
      setAuth0Token(dataToken);
    };

    if (!isAuthenticated) return;
    getTokens();
  }, [getAccessTokenSilently, isAuthenticated]);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (!isAuthenticated) return <Login />;

  return (
    <div className="flex w-screen bg-white">
      <Sidebar />
      <div className="min-h-screen grow">
        <Routes>
          <Route path="/" element={<LocationSelection />} />
          <Route path="/sites" element={<LocationSelection />} />
          <Route path="/sites/:siteId" element={<SiteDetail />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/alerts" element={<NoAccessPage />} />
          <Route path="/devices" element={<DevicesPage />} />
          <Route path="/marketplace" element={<NoAccessPage />} />
          <Route path="/billing" element={<NoAccessPage />} />
          <Route path="/users" element={<NoAccessPage />} />
          <Route path="/thirdparty" element={<ThirdParty />} />
          <Route
            path="/:providerName/customerscopeselection"
            element={<ScopeSelection />}
          />
          <Route path="/response/" element={<ResponsePage />} />
        </Routes>
      </div>
      <NonProdEnvironmentWarning />
    </div>
  );
};

export default App;
