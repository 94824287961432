let baseUrl;

switch (process.env.REACT_APP_ENV) {
  case "production":
    baseUrl = "https://ingestion.spectra.api.aerovy.com/v1";
    break;
  case "staging":
    baseUrl = "https://ingestion.spectra.api.staging.aerovy.com/v1";
    break;
  case "development":
    baseUrl = "https://ingestion.spectra.api.dev.aerovy.com/v1";
    break;
  default:
    baseUrl = "/ingestion/v1";
}

export async function addSite(data) {
  const addSiteUrl = `${baseUrl}/site`;

  try {
    const response = await fetch(addSiteUrl, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        "partner-id": localStorage.getItem("partnerId"),
      },
      body: JSON.stringify(data),
    });

    if (response.status === 201) {
      return { success: true, message: "Site created successfully" };
    } else {
      const errorData = await response.json();
      console.error("Error creating site:", errorData);
      return { success: false, error: errorData };
    }
  } catch (error) {
    console.error("Error creating site:", error);
    return { success: false, error: error.message };
  }
}
