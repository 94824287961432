import { useAuth0 } from "@auth0/auth0-react";

const Settings = () => {
  const { logout } = useAuth0();

  return (
    <>
      <div className="text-heading1 text-space50 pt-4 pb-5">Settings</div>
      <button
        type="submit"
        className="border-red40 bg-red50 hover:bg-red60 elevation-2 mb-3 items-center justify-center gap-2 rounded border px-4 py-2.5 font-bold text-white"
        onClick={() =>
          logout({ logoutParams: { returnTo: window.location.origin } })
        }
      >
        Logout
      </button>
    </>
  );
};

export default Settings;
