import { SiteResponse } from "api/ingestion/places.ts";
import { Thing } from "api/ingestion/thing.ts";
import { DeviceTile } from "components";

import { useEffect, useState } from "react";

type SiteDetails = SiteResponse & { devices: Thing[] };

const SiteInformation = ({
  siteId,
  siteName,
  address,
  devices,
}: {
  siteId: string;
  siteName: string;
  address: string;
  devices: Thing[];
}) => {
  return (
    <div className="flex flex-col gap-2">
      <div>
        <p className="text-heading3 text-space50">{siteName}</p>
        <p className="text-caption mt-0 text-space70">{address}</p>
      </div>

      <div className="flex flex-wrap gap-4">
        {devices.map((device) => (
          <DeviceTile device={device} key={device.thingName} />
        ))}
      </div>
    </div>
  );
};

const DeviceTypeSection = ({
  thingType,
  sites,
}: {
  thingType: string;
  sites: SiteDetails[];
}) => {
  return (
    <>
      <p className="text-heading2 text-space50">{thingType}</p>
      <div className="flex flex-col p-4 gap-4 elevation-1 rounded-md mb-2">
        {sites.map((site) => (
          <SiteInformation {...site} key={site.siteId} />
        ))}
      </div>
    </>
  );
};

const DevicesByType = ({
  sites,
  deviceTypes,
  devices,
}: {
  sites: SiteResponse[];
  deviceTypes: string[];
  devices: Thing[];
}) => {
  const [groupedDevices, setGroupedDevices] = useState([]);

  useEffect(() => {
    // group by device type
    const temp = deviceTypes.map((deviceType) => {
      // group again by site
      const devicesBySite = devices
        .filter((device) => device.thingType === deviceType)
        .reduce((acc, device) => {
          if (!acc[device.attributes.SiteId]) {
            acc[device.attributes.SiteId] = {
              ...sites.find((site) => site.siteId === device.attributes.SiteId),
              devices: [],
            };
          }

          acc[device.attributes.SiteId].devices.push(device);

          return acc;
        }, {});

      return {
        thingType: deviceType,
        sites: Object.values(devicesBySite),
      };
    });

    setGroupedDevices(temp);
  }, [sites, deviceTypes, devices]);

  return (
    <div className="flex flex-col gap-3">
      {groupedDevices
        .filter((d) => d.sites.length > 0)
        .map((deviceType) => (
          <DeviceTypeSection {...deviceType} key={deviceType.thingType} />
        ))}
      ;
    </div>
  );
};

export { DevicesByType };
