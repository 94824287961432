import { SiteResponse } from "api/ingestion/places.ts";
import { Thing } from "api/ingestion/thing.ts";
import { DeviceTile } from "components";

import { useEffect, useState } from "react";

type DeviceType = {
  thingType: string;
  devices: Thing[];
};

const DeviceTypeInformation = ({
  thingType,
  devices,
}: {
  thingType: string;
  devices: Thing[];
}) => {
  return (
    <div className="flex flex-col gap-2">
      <p className="text-heading3 text-space50">{thingType}</p>

      <div className="flex flex-wrap gap-4">
        {devices.map((device) => (
          <DeviceTile device={device} key={device.thingName} />
        ))}
      </div>
    </div>
  );
};

const SiteSection = ({
  siteId,
  siteName,
  address,
  deviceTypes,
}: {
  siteId: string;
  siteName: string;
  address: string;
  deviceTypes: DeviceType[];
}) => {
  return (
    <>
      <div>
        <p className="text-heading2 text-space50">{siteName}</p>
        <p className="text-caption mt-0 text-space70">{address}</p>
      </div>
      <div className="flex flex-col p-4 gap-4 elevation-1 rounded-md mb-2">
        {deviceTypes.map((deviceType) => (
          <DeviceTypeInformation {...deviceType} key={deviceType} />
        ))}
      </div>
    </>
  );
};

const DevicesBySite = ({
  sites,
  deviceTypes,
  devices,
}: {
  sites: SiteResponse[];
  deviceTypes: string[];
  devices: Thing[];
}) => {
  const [groupedDevices, setGroupedDevices] = useState([]);

  useEffect(() => {
    // group by site
    const temp = sites.map((site) => {
      // group again by device type
      const devicesByType = devices
        .filter((device) => device.siteId === site.siteId)
        .reduce((acc, device) => {
          if (!acc[device.thingType]) {
            acc[device.thingType] = {
              thingType: device.thingType,
              devices: [],
            };
          }

          acc[device.thingType].devices.push(device);

          return acc;
        }, {});

      return {
        ...site,
        deviceTypes: Object.values(devicesByType),
      };
    });

    setGroupedDevices(temp);
  }, [sites, deviceTypes, devices]);

  return (
    <div className="flex flex-col gap-3">
      {groupedDevices.map((site) => {
        return <SiteSection {...site} key={site.siteId} />;
      })}
    </div>
  );
};

export { DevicesBySite };
