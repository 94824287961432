export const header = 'Billing & Invoicing'
export const pageTree = ['Skyports', 'All Locations']

export const displayProps = {
  "Invoices Unpaid": <><p className="font-bold mr-1">7</p>Invoices</>,
  "Invoiced Ammount Unpaid": <><p className="font-bold mr-1">{`$${12312}`}</p>USD</>,
  "Payments Processing": <><p className="font-bold mr-1">{`$${1235}`}</p>USD</>,
}

export const outstandingStats = {
  'Invoices Outstanding': {
    'value': 8,
    'units': 'Invoices'
  },
  'Charges Outstanding (USD)': {
    'value': 8123,
    'units': 'USD'
  }
}

export const historyStats = {
  '# Paid': {
    'value': 8123,
    'units': 'Invoices'
  },
}

export const chargeEvents = [
  ["Operator Name 1", "ID1", "VT1", "7:01", "7:43", "Palo Alto, CA", 32, 43, 0.67, 76, 1],
  ["Operator Name 2", "ID2", "VT2", "7:01", "7:43", "Palo Alto, CA", 32, 43, 0.67, 76, 2],
  ["Operator Name 3", "ID3", "VT3", "7:01", "7:43", "Palo Alto, CA", 32, 43, 0.67, 76, 3],
  ["Operator Name 4", "ID4", "VT4", "7:01", "7:43", "Palo Alto, CA", 32, 43, 0.67, 76, 4],
  ["Operator Name 5", "ID5", "VT5", "7:01", "7:43", "Palo Alto, CA", 32, 43, 0.67, 76, 5],
  ["Operator Name 6", "ID6", "VT6", "7:01", "7:43", "Palo Alto, CA", 32, 43, 0.67, 76, 6],
  ["Operator Name 7", "ID7", "VT7", "7:01", "7:43", "Palo Alto, CA", 32, 43, 0.67, 76, 7],
  ["Operator Name 8", "ID8", "VT8", "7:01", "7:43", "Palo Alto, CA", 32, 43, 0.67, 76, 8],
  ["Operator Name 9", "ID9", "VT9", "7:01", "7:43", "Palo Alto, CA", 32, 43, 0.67, 76, 9],
]