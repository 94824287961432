import {
  TimeseriesValue,
  getSummaryForThings,
  mergeDatapoints,
} from "api/data.ts";
import { Site } from "api/ingestion/places.ts";
import { getThingsFromLevelGroup } from "api/ingestion/things.ts";
import { ProgressBar } from "components/devices/DeviceTile";
import { useSelectedTimeRange } from "context/SelectedTimeRangeContext.tsx";

import { useEffect, useState } from "react";

export const LevelGroup = ({
  siteId,
  levelGroup,
}: {
  siteId: string;
  levelGroup: SiteLevelGroup;
}) => {
  const { start, end } = useSelectedTimeRange();

  const [data, setData] = useState<TimeseriesValue>();

  useEffect(() => {
    getThingsFromLevelGroup(siteId, levelGroup.levelGroupId)
      .then((things) => {
        return things.map((e) => e.thingId);
      })
      .then((thingIds) => {
        return getSummaryForThings(siteId, thingIds, start, end);
      })
      .then((e) => mergeDatapoints(e.dataPoints))
      .then(setData)
      .catch(console.error);
  }, [siteId, levelGroup.levelGroupId, start, end]);

  return (
    <div className="py-3 border border-gray-200 rounded-md">
      <p className="text-caption px-3 pb-3">{levelGroup.levelGroupName}</p>
      <div className="gap-px flex flex-col">
        <ProgressBar
          value={data?.fwd ?? "-"}
          percentage={data?.fwd / data?.fwd}
          label="Forward"
          unit="kWh"
        />
        <ProgressBar
          value={data?.reverse ?? "-"}
          percentage={data?.reverse / data?.reverse}
          label="Reverse"
          unit="kWh"
        />
        <ProgressBar
          value={data?.net ?? "-"}
          percentage={data?.net / data?.net}
          label="Net"
          unit="kWh"
        />
      </div>
    </div>
  );
};

export const LevelGroupDetails = ({ site }: { site: Site }) => {
  if (!site) return <div>Loading...</div>;

  return (
    <div className="p-3">
      <p className="text-space50 text-heading3 mb-4">Site Groups</p>
      {site.levelGroups.map((levelGroup) => (
        <div key={levelGroup.levelGroupId}>
          <LevelGroup siteId={site.siteId} levelGroup={levelGroup} />
        </div>
      ))}
    </div>
  );
};
