let baseUrl;

switch (process.env.REACT_APP_ENV) {
  case "production":
    baseUrl = "https://ingestion.spectra.api.aerovy.com/v1";
    break;
  case "staging":
    baseUrl = "https://ingestion.spectra.api.staging.aerovy.com/v1";
    break;
  case "development":
    baseUrl = "https://ingestion.spectra.api.dev.aerovy.com/v1";
    break;
  default:
    baseUrl = "/ingestion/v1";
}

export async function validateToken(integrationId, token) {
  const getValidateTokenUrl = `${baseUrl}/integrations/${integrationId}/validate/${token}`;

  try {
    const response = await fetch(getValidateTokenUrl, {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth0_token")}`,
      },
    });
    if (response.status === 200) {
      return { isValid: true };
    } else {
      return { isValid: false, error: "Validation failed" };
    }
  } catch (error) {
    return { isValid: false, error: error.message };
  }
}

export async function validateTokenFlow(token) {
  const getIntegrationsUrl = `${baseUrl}/integrations`;

  try {
    const response = await fetch(getIntegrationsUrl, {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth0_token")}`,
      },
    });
    const integrations = await response.json();
    const integration = integrations.find(
      (integration) =>
        integration.partnerId === localStorage.getItem("partnerId"),
    );
    if (integration) {
      return validateToken(integration.integrationId, token);
    } else {
      console.error(
        "Integration not found for the specified partnerId and thirdPartyId.",
      );
      return { isValid: false, error: "Integration not found" };
    }
  } catch (error) {
    console.error("Error fetching integrations:", error);
    return { isValid: false, error: error.message };
  }
}

export async function thirdPartyFlow(parameters, providerName) {
  const getIntegrationsUrl = `${baseUrl}/integrations`;
  console.log(getIntegrationsUrl);

  try {
    const response = await fetch(getIntegrationsUrl, {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth0_token")}`,
      },
    });
    const integrations = await response.json();
    const integration = integrations.find(
      (integration) =>
        integration.partnerId === localStorage.getItem("partnerId") &&
        integration.thirdPartyId.toLowerCase() === providerName.toLowerCase(),
      // integration.metaData?.
    );
    console.log(integration);
    if (integration) {
      updateIntegrationMetaData(parameters, integration);
      return integration.integrationId;
    } else {
      console.error(
        "Integration not found for the specified partnerId and thirdPartyId.",
      );
      return null;
    }
  } catch (error) {
    console.error("Error fetching integrations:", error);
    throw error;
  }
}

const updateIntegrationMetaData = async (parameters, integration) => {
  const getIntegrationsUrl = `${baseUrl}/integrations/${integration?.integrationId}/metaData`;
  const metaDataPayload = {
    metaData: parameters,
    partnerThirdPartyId: integration?.partnerThirdPartyId,
  };

  console.log(getIntegrationsUrl);
  console.log(metaDataPayload);

  try {
    const response = await fetch(getIntegrationsUrl, {
      method: "PATCH",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth0_token")}`,
      },
      body: JSON.stringify(metaDataPayload),
    });
    if (!response.ok) {
      throw new Error("Failed to update integration metaData.");
    }
  } catch (error) {
    console.error("Error updating integration metaData:", error);
  }
};

export const postIntegration = async (selectedSiteId, customerId) => {
  const getIntegrationsUrl = `${baseUrl}/integrations`;
  console.log(getIntegrationsUrl);

  const payload = {
    siteId: selectedSiteId,
    thirdPartyId: "SCE",
    partnerThirdPartyId: customerId,
    metaData: {},
  };

  try {
    const response = await fetch(getIntegrationsUrl, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth0_token")}`,
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText || "Initialization failed. Please try again.");
    }
    return { success: true };
  } catch (error) {
    return { success: false, error: error.message };
  }
};

export async function getSites() {
  const getSitesUrl = `${baseUrl}/sites/`;
  try {
    const response = await fetch(getSitesUrl, {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth0_token")}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error, status: ${response.status}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Fetch Error:", error);
    throw error;
  }
}
