import { useState } from "react";

const AddSiteModal = ({ isOpen, onClose, onAddSite }) => {
  const [formData, setFormData] = useState({
    siteName: '',
    address: '',
    latitude: '',
    longitude: '',
  });

  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const validateForm = () => {
    let valid = true;
    let errors = {};

    if (!formData.siteName) {
      errors.siteName = 'Site Name is required';
      valid = false;
    }
    if (!formData.address) {
      errors.address = 'Address is required';
      valid = false;
    }
    if (!formData.latitude) {
      errors.latitude = 'Latitude is required';
      valid = false;
    } else if (formData.latitude < -90 || formData.latitude > 90) {
      errors.latitude = 'Latitude must be between -90 and 90';
      valid = false;
    }
    if (!formData.longitude) {
      errors.longitude = 'Longitude is required';
      valid = false;
    } else if (formData.longitude < -180 || formData.longitude > 180) {
      errors.longitude = 'Longitude must be between -180 and 180';
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const handleAddSite = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    onAddSite(formData)
      .then((result) => {
        if (result.success) {
          setFormData({
            siteName: '',
            address: '',
            latitude: '',
            longitude: '',
          });
          setErrors({});
          setErrorMessage('');
          onClose();
        } else {
          setErrorMessage(result.error);
        }
      });
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-xl mb-4">Add Site</h2>
        <form onSubmit={handleAddSite}>
          <div className="mb-4">
            <label
              className="text-heading3"
              htmlFor="siteName"
            >
              Site Name
            </label>
            <input
              type="text"
              name="siteName"
              id="siteName"
              value={formData.siteName}
              onChange={handleChange}
              className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.siteName ? 'border-red-500' : ''
              }`}
            />
            {errors.siteName && (
              <p className="text-red-500 text-xs italic">
                {errors.siteName}
              </p>
            )}
          </div>
          <div className="mb-4">
            <label
              className="text-heading3"
              htmlFor="address"
            >
              Site Address
            </label>
            <input
              type="text"
              name="address"
              id="address"
              value={formData.address}
              onChange={handleChange}
              className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.address ? 'border-red-500' : ''
              }`}
            />
            {errors.address && (
              <p className="text-red-500 text-xs italic">
                {errors.address}
              </p>
            )}
          </div>
          <div className="mb-4">
            <label
              className="text-heading3"
              htmlFor="latitude"
            >
              Latitude
            </label>
            <input
              type="number"
              name="latitude"
              id="latitude"
              value={formData.latitude}
              onChange={handleChange}
              className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.latitude ? 'border-red-500' : ''
              }`}
            />
            {errors.latitude && (
              <p className="text-red-500 text-xs italic">
                {errors.latitude}
              </p>
            )}
          </div>
          <div className="mb-4">
            <label
              className="text-heading3"
              htmlFor="longitude"
            >
              Longitude
            </label>
            <input
              type="number"
              name="longitude"
              id="longitude"
              value={formData.longitude}
              onChange={handleChange}
              className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.longitude ? 'border-red-500' : ''
              }`}
            />
            {errors.longitude && (
              <p className="text-red-500 text-xs italic">
                {errors.longitude}
              </p>
            )}
          </div>
          {errorMessage && (
            <p className="text-red-500 text-xs italic mb-4">
              {errorMessage}
            </p>
          )}
          <div className="flex justify-end space-x-4">
            <button
              type="submit"
              className="px-3.5 py-2 rounded-full border border-space80 bg-blue90 justify-end items-center gap-1 cursor-pointer flex"
            >
              Add Site
            </button>
            <button
              type="button"
              onClick={onClose}
              className="px-3.5 py-2 rounded-full border border-space80 justify-end items-center gap-1 cursor-pointer flex"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddSiteModal;